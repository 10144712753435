<template>
  <div id="xz_index">

  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  mounted() {
    location.href="https://www.baidu.com/"
  },
  methods: {

  },
};
</script>



<style scoped>

</style>